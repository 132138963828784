@import 'variables.custom';

// BEGIN: Order
.order-status-badge {
  &-open {
    color: $order-status-open !important;
    background-color: $bg-order-status-open !important;
  }
  &-shipped {
    color: $order-status-shipped !important;
    background-color: $bg-order-status-shipped !important;
  }
  &-awaiting-payment {
    color: $order-status-awaiting-payment !important;
    background-color: $bg-order-status-awaiting-payment !important;
  }
  &-on-hold {
    color: $order-status-on-hold !important;
    background-color: $bg-order-status-on-hold !important;
  }
  &-cancelled {
    color: $order-status-cancelled !important;
    background-color: $bg-order-status-cancelled !important;
  }
}
// END: Order

// BEGIN: Shipment
.shipment-refund-badge {
  &-submitted {
    color: $order-status-open !important;
    background-color: $bg-order-status-open !important;
  }
  &-pending {
    color: $order-status-on-hold !important;
    background-color: $bg-order-status-on-hold !important;
  }
  &-pending_disputed {
    color: $order-status-on-hold !important;
    background-color: $bg-order-status-on-hold !important;
  }
  &-pending_exceptions {
    color: $order-status-on-hold !important;
    background-color: $bg-order-status-on-hold !important;
  }
  &-pending_server_down {
    color: $order-status-on-hold !important;
    background-color: $bg-order-status-on-hold !important;
  }
  &-refunded {
    color: $order-status-shipped !important;
    background-color: $bg-order-status-shipped !important;
  }
  &-rejected {
    color: $order-status-cancelled !important;
    background-color: $bg-order-status-cancelled !important;
  }
}
// END: Shipment

// BEGIN: Claim
.claims-status-badge {
  &-unsubmitted {
    color: $claims-status-unsubmitted !important;
    background-color: $bg-claims-status-unsubmitted !important;
  }
  &-in-progress {
    color: $claims-status-in-progress !important;
    background-color: $bg-claims-status-in-progress !important;
  }
  &-needs-more-info {
    color: $claims-status-needs-more-info !important;
    background-color: $bg-claims-status-needs-more-info !important;
  }
  &-approved {
    color: $claims-status-approved !important;
    background-color: $bg-claims-status-approved !important;
  }
  &-denied {
    color: $claims-status-denied !important;
    background-color: $bg-claims-status-denied !important;
  }
  &-appealed {
    color: $claims-status-appealed !important;
    background-color: $bg-claims-status-appealed !important;
  }

  &-reason-lost {
    color: $claims-reason-lost !important;
  }

  &-reason-damage {
    color: $claims-reason-damage !important;
  }

  &-reason-delivered_incorrect_address {
    color: $claims-reason-delivered-incorrect-address !important;
  }

  &-reason-missing_contents {
    color: $claims-reason-missing-content !important;
  }
}
// END: Claim

// BEGIN: Report
.report-status-badge {
  &-new {
    color: $order-status-open !important;
    background-color: $bg-order-status-open !important;
  }
  &-done {
    color: $order-status-shipped !important;
    background-color: $bg-order-status-shipped !important;
  }
  &-processing {
    color: $order-status-awaiting-payment !important;
    background-color: $bg-order-status-awaiting-payment !important;
  }
  &-failed {
    color: $order-status-cancelled !important;
    background-color: $bg-order-status-cancelled !important;
  }
}
// END: Report

// BEGIN: Tooltip
.tooltip-error .tooltip-inner {
  background-color: $bg-tooltip-error;
}
// END: Tooltip
