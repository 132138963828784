//Help Center's Color
$help-center-color-a: #c3f1db;
$help-center-color-b: #e9f2ec;
$help-center-color-c: #ffffff;
$help-center-color-d: #ffffff;
$help-center-gradient: linear-gradient(
  $help-center-color-a,
  $help-center-color-b,
  $help-center-color-c,
  $help-center-color-d
);
$dark-gray: #475569;
$dark-copy: #27364b;

.bg-image-help-center {
  background-image: $help-center-gradient !important;
}

.blue-shadow {
  box-shadow: 0px 8px 12px 0px rgba(22, 186, 168, 0.15), 0px 0px 1px 0px rgba(22, 186, 168, 0.31);
}

section.video-section.container {
  margin-top: 240px;
  margin-bottom: 123px;

  > div {
    gap: 30px 62px;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    > * {
      width: 100%;
    }

    > div {
      text-align: center;

      @media (min-width: 768px) {
        max-width: 363px;
        text-align: left;
      }

      h3 {
        font-size: 24px;
        line-height: normal;
      }

      p {
        color: $dark-copy;
        font-size: 30px;
        line-height: 50px;
        /* 166.667% */
      }
    }

    > iframe {
      width: 100%;
      aspect-ratio: 16 / 9;

      @media (min-width: 768px) {
        width: 600px;
        max-width: 600px;
      }
    }
  }
}

section.email-form-container {
  p {
    font-size: 40px;
    line-height: 50px;
    /* 125% */
  }
}

// START Help Center Level 1 - Main Page //
.help-center-main {
  section.title-section.container {
    padding-top: 7.5rem !important;

    h2.help-center-title {
      color: $dark-gray;
      font-size: 56px;
      line-height: normal;
    }
  }

  section.help-center-section.container {
    padding-top: 4rem !important;

    div.help-center-grid {
      gap: 40px 25px;

      div.help-center-card {
        width: 374px;

        > div {
          min-height: 420px;
          border: 1px solid white;

          &:hover {
            border: 1px solid #14b8a6;
          }

          img {
            width: 88px;
          }

          h3.card-title {
            font-size: 27px;
            font-weight: 600;
            line-height: normal;
          }

          ul.hashtags {
            height: 80px;

            li {
              a {
                position: relative;
                color: #94a3b8;
                font-size: 14px;
                line-height: normal;
                z-index: 10;
              }
            }
          }

          p {
            color: $dark-gray;
            font-size: 16px;
            line-height: normal;
          }

          .link-card-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            opacity: 0;
          }
        }
      }
    }
  }
}

// END Help Center Level 1 - Main Page //

// START Help Center Level 2 Pages - Subject Profile //
.subject-profile-container {
  padding-top: 75.45px;

  section.title-section {
    h2 {
      color: $dark-gray;
      font-size: 56px;
      line-height: normal;
      text-transform: capitalize;
    }

    p {
      color: $dark-gray;
      font-size: 16px;
      line-height: normal;
    }
  }

  section.subject-specific-links {
    div.subject-container {
      padding: 0;

      h3 {
        color: #1e2a3b;
        font-size: 24px;
        line-height: 50px;
        /* 208.333% */
        text-transform: capitalize;
      }

      &:last-of-type {
        margin-bottom: 240px;
      }
    }
  }
}

.subject-link {
  p {
    color: $dark-copy;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    line-height: 150%;
    /* 36px */
    letter-spacing: -0.456px;
  }

  + .subject-link {
    margin-top: 27px;
  }
}

// END Help Center Level 2 Pages - Subject Profile //

// START Help Center Level 3 Pages - Subject Details //
.subject-details-container {
  section.title-section {
    padding: 60px 0;

    h2 {
      font-size: 40px;
      line-height: 50px;
      /* 125% */
      text-transform: capitalize;
    }
  }

  > div.flex-container {
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
      position: relative;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding-top: 143px !important;
      max-width: 1744px !important;

      > * {
        flex: 1;
        max-width: 324px;
      }

      > div.larger-flex-item {
        flex: 2;
        max-width: 800px;
      }
    }
  }

  section.subject-details-breadcrumb-container {
    width: 100%;
    margin: auto;
    position: relative;

    @media (min-width: 1024px) {
      position: absolute;
      top: 0;
      left: 10%;
      width: fit-content;
    }

    @media (min-width: 1920px) {
      left: 256px;
    }
  }

  div.subject-details-content {
    width: 100%;
    margin: auto;
    padding-bottom: 214px !important;

    @media (min-width: 1620px) {
      padding: 0 15px 214px !important;
    }

    h3 {
      color: #1e2a3b;
      font-size: 28px;
      line-height: normal;
    }

    .instruction-container {
      iframe {
        width: 100%;
        aspect-ratio: 16 / 9;

        @media (min-width: 768px) {
          max-width: 770px;
        }
      }

      ol,
      ul {
        margin-top: 36px;

        &.less-spacing {
          li + li {
            margin-top: 0;
          }
        }

        &.indent {
          padding-left: 60px;
        }

        li {
          color: #64748b;
          font-size: 18px;
          font-weight: 500;
          line-height: normal;

          + li {
            margin-top: 36px;
          }

          > img {
            display: block;
            margin-top: 30px;
            width: 770px;
            height: auto;
            max-width: 100%;
          }
        }
      }

      p {
        color: #64748b;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        margin: 0;

        + p {
          margin-top: 36px;
        }

        &.indent {
          padding-left: 60px;
        }
      }

      img {
        max-width: 100%;
        box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.08);
        border-radius: 0.3rem;
        margin-top: 15px;
      }

      code:not(.json) {
        display: block;
        margin-top: 15px;
        margin-left: 15px;
        padding: 10px;
        font-size: 13px;
        background-color: white;
        color: black;

        span {
          display: block;

          + span {
            margin-top: 6px;
          }
        }

        .indent,
        .indented {
          padding-left: 15px;
        }
      }

      .json-container {
        flex-direction: column;
        gap: 15px;

        @media (min-width: 768px) {
          flex-direction: row;
        }

        > div {
          &.request {
            @media (min-width: 768px) {
              flex: 1;
              width: 70%;
            }
          }

          &.response {
            @media (min-width: 768px) {
              flex: 1;
              width: 30%;
            }
          }
        }
      }

      pre {
        font-family: monospace;
        line-height: 1.5;
        white-space: pre-wrap;
        word-wrap: break-word;
        font-weight: 400;
        color: #b93993;
        box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.08);
        border-radius: 0.3rem;
        display: block;
        margin-top: 15px;
        padding: 10px;
        font-size: 13px;
        background-color: white;
        color: black;

        code {
          background-color: transparent;
          box-shadow: none;

          .json-key {
            color: brown;
          }

          .json-string {
            color: green;
          }

          .json-number {
            color: blue;
          }

          .json-boolean {
            color: darkorange;
          }

          .json-null {
            color: magenta;
          }

          .json-brace {
            color: black;
          }
        }
      }

      .request-type {
        display: inline-block;
        padding: 8px;
        color: white;
        border-radius: 5px;
        margin-right: 5px;
        background-color: #14b8a6;

        &.post {
          background-color: #14b8a6;
        }

        &.get {
          background-color: #61affe;
        }
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;

    th,
    td {
      border: 1px solid black;
      padding: 8px;
      text-align: left;
      font-size: 13px;
    }

    th {
      background-color: #14b8a6 !important;
      color: white;
    }

    td {
      background-color: white;
      vertical-align: top;
    }
  }
}

// END Help Center Level 3 Pages - Subject Details //

// START Help Center Menu //
a#help-center-menu-button {
  position: sticky;
  z-index: 1;
  display: block;
  width: calc(100% + 40px);
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(30px);
  top: 100px;
  margin: 0 -20px;

  @media (min-width: 443px) {
    top: 69.79px;
  }

  @media (min-width: 768px) {
    top: 75.45px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
}

div#help-center-menu {
  position: sticky;
  top: 150px;
  width: calc(100% + 40px);
  margin: 0 -20px;
  height: 100vh;
  padding: 0 15px;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(30px);
  max-height: 0;
  overflow: hidden;
  transition: max-height 500ms ease-in-out;

  @media (min-width: 443px) {
    top: 120px;
  }

  @media (min-width: 768px) {
    top: 128px;
  }

  &.open {
    max-height: calc(100vh - 150px);

    @media (min-width: 443px) {
      max-height: calc(100vh - 120px);
    }

    @media (min-width: 768px) and (max-width: 1399px) {
      max-height: calc(100vh - 128px);
    }
  }

  > ul {
    padding: 0 20px;

    > li {
      + li {
        padding-top: 5px;
        margin-top: 5px;
      }

      > ul {
        margin-top: 6px;
        max-height: 0;
        transition: max-height 0.25s linear;
        overflow: hidden;

        &.expanded {
          max-height: 750px;
        }

        > li {
          font-size: 16px;
          line-height: normal;

          + li {
            margin-top: 10px;
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    top: 80px;
    margin: 0 0 30px;
    padding: 20px;
    width: 100%;
    height: fit-content;
    max-width: 324px;
    max-height: calc(100vh - 80px);
    overflow-y: scroll;
    border-radius: 20px;
    border: 1px solid #14b8a6;

    h4 {
      color: #14b8a6;
      font-size: 20px;
      line-height: normal;
    }
  }
}

// END Help Center Menu //
